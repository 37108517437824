import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'


class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="KitchenLab &bull; Webapps" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <h2>Webapps</h2>
          </section>

          <section id="content2" className="main">
            <h3>COVID-19 antibody test performance</h3>
            <p>
              In an effort to support the work evaluating technologies for serology (antibody) testing, the lab has developed an 
              interactive webapp to explore the effect of changing prevalence on the population-level performance of these tests.
            </p>
            <p>
              Because the fraction of people in the general population who have been exposed to COVID-19 (which we call the prevalence) 
              is still low, even antibody tests with high sensitivity and specificity may end up mis-classifying a large number of people.  
              To address this we created an interactive web app that shows the effect of changing the prevalence (as well as the test 
              performance) on the probability that given a positive test you actually have SARS-CoV-2 antibodies.  We have included data 
              from other antibody test evaluations as well as state and county-level prevalence values for a geographically-refined 
              interpretation of these results. 
            </p>
            <p>
              <Link to="https://covid.omics.kitchen" className="button" target="_blank">covid.omics.kitchen</Link>
            </p>
          </section>
          
          <section id="content3" className="main">
            <h3>miRNA tissue atlas</h3>
            <p>
              Good RNA biomarkers tend to be specifically expressed in tissues relevant to the disease under investigation. 
              In collaboration with the van Keuren-Jensen lab at TGen, we have created a human small-RNA tissue atlas. 
              The interactive webapp enables discovery and deeper interrogation of tissue-specific miRNAs to accelerate community 
              efforts to develop informative non-invasive biomarkers of many different diseases.
            </p>
            <p>
              <ul className="actions">
                <li>
                  <Link to="https://microrna-atlas.omics.kitchen/" className="button" target="_blank">Explore the miRNA Atlas</Link>
                </li>
                <li>
                  <Link to="http://data.omics.kitchen/miRNAatlas/" className="button" target="_blank">Interrogate specific miRNAs</Link>
                </li>
              </ul>
            </p>
          </section>
          
        </div>
      </Layout>
    )
  }
}

export default Generic
